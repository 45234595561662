<template>
  <div class="h-full" v-if="!isLoading">
    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 pl-2" v-if="count !== routesListNew.length">
      <div @click="$router.push({name: panel.name})" style="height: 100px;" class="relative shadow cursor-pointer rounded p-4 bg-white mt-10" v-for=" (panel, index) in routesListNew" :key="index" v-show="panel.hidden">
        <p class="text-right text-primary2 heading-3">
          {{panel.meta.title}}
        </p>
        <div class="card_icon bg-primary2 flex justify-center items-center">
          <component  :is="panel.meta.routeIcon" v-bind="panel.meta.routIconSize" :color="'white'"></component>
        </div>
      </div>
    </div>
    <div v-if="count === routesListNew.length" class="rounded bg-white grid grid-cols-12 " :style="`height:` +  mainHeight + `px; !important`">
      <div class=" mx-2 shadow-md relative box col-span-12">
        <div class="bg-white cust_card p-2 heading-3 text-text1 justify-between flex">
          <p>Dashboard</p>
        </div>
        <div style="overflow-y: auto; white-space: nowrap;" class="table_containder m-2">
          <div class="">
            <span class="heading-3 text-text2 ">You are logged in!!</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dashboardSvg from '@/assets/images/svg/dashboardSvg.vue'
import accountingSvg from '@/assets/images/svg/accountingSvg.vue'
import cogsSvg from '@/assets/images/svg/cogsSvg.vue'
import consumerSalesSvg from '@/assets/images/svg/consumerSalesSvg.vue'
import dealerNetworkSvg from '@/assets/images/svg/dealerNetworkSvg.vue'
import productionSvg from '@/assets/images/svg/productionSvg.vue'
import shippingSvg from '@/assets/images/svg/shippingSvg.vue'
export default {
  components: {
    dashboardSvg,
    accountingSvg,
    cogsSvg,
    consumerSalesSvg,
    dealerNetworkSvg,
    productionSvg,
    shippingSvg
  },
  data () {
    return {
      mainHeight: 0,
      count: 0,
      isLoading: true,
      adminPanels: [],
      routesListNew: [],
      cardHeight: window.innerHeight - 220
    }
  },
  created() {
    document.title= 'Dashboard'
  },
  mounted () {
    this.mainHeight = window.innerHeight - 90
    setTimeout(() => {
      this.getAdminPanel()
    }, 300);
  },
  methods: {
    getAdminPanel () {
      this.isLoading = true
      let routingList = JSON.parse(localStorage.getItem('routingData'))
      for (let index = 0; index < routingList.children.length; index++) {
        if (routingList.children[index].name !== 'Dashboard') {
          if (routingList.children[index].listing.length > 0) {
            this.routesListNew.push(routingList.children[index])
          }
        }
      }
      this.count = 0
      this.routesListNew.forEach(element => {
        if (!element.hidden) {
          this.count += 1
        }
      });
      this.isLoading = false
      console.log('this.routesListNew111', this.routesListNew)
    }
  }
}
</script>
<style scoped>
.card-content {
  background-color: transparent;
  padding: 0rem;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
}
.sheets {
  border-radius: 10%;
  width: 70px !important;
  height: 67px !important;
  justify-content: center;
  display: flex;
  margin-top: -40px;
  border-color: #26c6da;
}
.hoverableCard:hover {
  box-shadow: 1px 5px 5px 1px rgba(38, 38, 38, 0.2);
  top: 0px;
  border: 0px solid #cccccc;
  /* background-color: rgb(244, 244, 244); */
  /* box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2); */
  /* transition:all 0.5s; */
  /* border: 0px solid #cccccc; */
}
.cardAbsolute {
  position: absolute;
  left: 20px;
  top: -30px;
  border-radius: 8%;
  width: 80px !important;
  height: 80px !important;
  text-align: center;
  justify-content: center;
  display: flex;
}
.marginTop {
  margin-top: 30px;
}

.card_icon{
      position: absolute;
    left: 20px;
    top: -30px;
    border-radius: 8%;
    width: 80px!important;
    height: 80px!important;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
</style>
